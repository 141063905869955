/*
 * Content:
 *
 * Footer
 */


// Footer
// ============================================================================

footer.footer {
  min-height: $footer-min-height;
  background-color: $gray-400;
}
